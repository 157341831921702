import { defineStore, acceptHMRUpdate } from 'pinia';
import { reactive, ref } from 'vue';

interface AppElements {
  heroPage: HTMLDivElement | null;
  character: {
    eyeRight: SVGAElement | null;
    armLeft: SVGAElement | null;
    mouth: SVGAElement | null;
    cloud: SVGAElement | null;
  };
  contactPage: HTMLElement | null;
  aboutPage: HTMLElement | null;
  aboutHeading: HTMLHeadingElement | null;
  aboutPortrait: HTMLImageElement | null;
  qualityItem: HTMLDivElement[] | null;
  aboutBkBlur: HTMLDivElement | null;
  aboutBtnContainer: HTMLDivElement | null;
}

interface AppState {
  showNavMenu: boolean;
  showCookieMsg: boolean;
  lightMode: boolean;
  collapsedAppMenu: boolean;
  messages: {
    showConfirmedSignup: boolean;
  };
  nodes: AppElements;

  [key: string]: any;
}

type AppNodesKey = keyof AppElements;

export const useAppStore = defineStore(
  'appStore',
  () => {
    const state = reactive<AppState>({
      lightMode: false,
      showNavMenu: false,
      showCookieMsg: true,
      collapsedAppMenu: false,
      messages: {
        showConfirmedSignup: false,
      },
      nodes: {
        heroPage: null,
        contactPage: null,
        aboutPage: null,
        aboutHeading: null,
        aboutPortrait: null,
        qualityItem: null,
        aboutBkBlur: null,
        aboutBtnContainer: null,
        character: {
          eyeRight: null,
          armLeft: null,
          mouth: null,
          cloud: null,
        },
      },
    });

    const lightMode = ref(false);

    const auth = ref<{ token: string | null; expiresIn: string | null }>({
      token: null,
      expiresIn: null,
    });

    function toggleMobileNav(isOpen?: boolean) {
      if (typeof isOpen === 'boolean') {
        state.showNavMenu = isOpen;
      } else {
        state.showNavMenu = !state.showNavMenu;
      }
    }

    function setLightMode(isLight: boolean) {
      lightMode.value = isLight;
    }

    function hideCookieMsg() {
      state.showCookieMsg = false;
    }

    type NestedKeys = keyof AppState | `${keyof AppState}.${string}`;
    type NestedValues =
      | AppState[keyof AppState]
      | AppState[keyof AppState][keyof AppState[keyof AppState]];

    function updateState(key: NestedKeys, value: NestedValues) {
      if (!key) return;
      const nestedKeys = (key as string).split('.');
      if (nestedKeys.length === 2) {
        state[nestedKeys[0]][nestedKeys[1]] = value;
      } else {
        state[key as keyof AppState] = value;
      }
    }

    function setAuth(token: string, expiresIn: string) {
      auth.value.token = token;
      auth.value.expiresIn = expiresIn;
    }

    function addNode(
      key: AppNodesKey,
      value: HTMLElement,
      options?: { pushValue: boolean },
    ) {
      const nestedKeys = (key as string).split('.');
      if (options?.pushValue) {
        if (!state.nodes[key as keyof AppElements]) {
          state.nodes[key as keyof AppElements] = [value as HTMLDivElement];
        } else if (Array.isArray(state.nodes[key as keyof AppElements])) {
          (state.nodes[key as keyof AppElements] as HTMLDivElement[]).push(
            value as HTMLDivElement,
          );
        }
      } else if (nestedKeys.length === 2) {
        if (state.nodes[nestedKeys[0] as keyof AppElements]) {
          state.nodes[nestedKeys[0] as keyof AppElements][
            nestedKeys[1] as keyof AppElements
          ] = value;
        }
        return;
      } else {
        state.nodes[key as keyof AppElements] = value as HTMLDivElement;
      }
    }

    return {
      state,
      lightMode,
      auth,
      toggleMobileNav,
      setLightMode,
      hideCookieMsg,
      addNode,
      updateState,
      setAuth,
    };
  },
  {
    persist: {
      storage: persistedState.localStorage,
      paths: ['state.showCookieMsg', 'auth', 'messages'],
    },
  },
);

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot));
}
